import {FunctionComponent} from "react";

export const Footer: FunctionComponent = () => {
    return (
        <footer className="bg-[#00589F] border-t border-black">
            <div className="max-w-7xl mx-auto pt-4 pb-12 px-4 overflow-hidden sm:px-6 lg:px-8">
                <div className="mt-8 flex justify-center space-x-6"></div>
                <p className="mt-8 text-center text-base text-white">
                    CP Legacy is a clean-room reverse engineering project.
                </p>
                <p className="text-center text-base text-white">
                    Original Club Penguin™ audiovisual assets are used for archival purposes only.
                </p>
                <p className="text-center text-base text-white">
                    We are not affiliated with The Walt Disney Company and/or the Disney Interactive Media Group.
                </p>
                <p className="text-center text-base text-white">
                    <br></br>
                </p>
            </div>
        </footer>
    );
};

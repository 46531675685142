import {FunctionComponent, useState, useCallback} from "react";
import {Link} from "react-router-dom";
import cplLogo from "../img/cplLogo.png";
import registerUp from "../img/registerUp.png";
import registerOver from "../img/registerOver.png";
import playUp from "../img/playUp.png";
import playOver from "../img/playOver.png";

type BUTTON_TYPE = "register" | "play";

const SITE_LINKS = [];

const EXTERNAL_LINKS = [];

export const Header: FunctionComponent = () => {
    const [registerImg, setRegisterImg] = useState(registerUp);
    const [playImg, setPlayImg] = useState(playUp);

    const mouseOver = useCallback((type: BUTTON_TYPE) => {
        if (type === "register") {
            setRegisterImg(registerOver);
        } else {
            setPlayImg(playOver);
        }
    }, []);

    const mouseOut = useCallback((type: BUTTON_TYPE) => {
        if (type === "register") {
            setRegisterImg(registerUp);
        } else {
            setPlayImg(playUp);
        }
    }, []);

    return (
        <header className="bg-[#00589F] border-b border-black">
            <nav className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8" aria-label="Top">
                <div className="w-full py-6 flex items-center justify-between border-b border-white lg:border-none">
                    <div className="flex items-center">
                        <Link to="/">
                            <span className="sr-only">CPL</span>
                            <img className={"h-20 w-auto"} src={cplLogo} alt={""} />
                        </Link>
                        <div className="hidden ml-10 space-x-8 lg:block"></div>
                    </div>
                    <div className="ml-10 space-x-2">
                        <Link to="/register">
                            <img
                                className={"inline-block"}
                                src={registerImg}
                                onMouseOver={() => mouseOver("register")}
                                onMouseOut={() => mouseOut("register")}
                                alt={"Register"}
                            />
                        </Link>
                        <a href={"https://play.cplegacy.com"}>
                            <img
                                className={"inline-block"}
                                src={playImg}
                                onMouseOver={() => mouseOver("play")}
                                onMouseOut={() => mouseOut("play")}
                                alt={"Play Now!"}
                            />
                        </a>
                    </div>
                </div>
                <div className="py-4 flex flex-wrap justify-center space-x-6 lg:hidden">
                    {SITE_LINKS.map((link) => (
                        <Link
                            key={link.name}
                            to={link.to}
                            className={"text-base font-medium text-white hover:text-indigo-100"}
                        >
                            {link.name}
                        </Link>
                    ))}
                    {EXTERNAL_LINKS.map((link) => (
                        <a
                            key={link.name}
                            href={link.href}
                            target={"_blank"}
                            className={"text-base font-medium text-white hover:text-indigo-100"}
                        >
                            {link.name}
                        </a>
                    ))}
                </div>
            </nav>
        </header>
    );
};

import {Route, Routes} from "react-router-dom";
import ReactGA from "react-ga";
import {Home} from "./routes/Home";
import {Register} from "./routes/Register";
import {Support} from "./routes/Support";
import {Legal} from "./routes/Legal";
import {Verify} from "./routes/Verify";
import {PasswordReset} from "./routes/PasswordReset";
import {NotFound} from "./routes/NotFound";
import {WhyTheAds} from "./routes/WhyTheAds";

ReactGA.initialize("UA-223733104-1");

export function App() {
    return (
        <Routes>
            <Route path={"/verify"} element={<Verify />} />
            <Route path={"/password-reset"} element={<PasswordReset />} />
            <Route path={"*"} element={<NotFound />} />
        </Routes>
    );
}

import {FunctionComponent} from "react";
import {Helmet} from "react-helmet";
import {Link} from "react-router-dom";
import {Header} from "../components/Header";
import {Footer} from "../components/Footer";
import bgImg from "../img/bg.jpg";

export const NotFound: FunctionComponent = () => {
    return (
        <div className={"min-h-screen bg-white flex flex-col"}>
            <Helmet>
                <title>404 | Club Penguin Legacy</title>
            </Helmet>

            <Header />

            <div
                className="flex-grow px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8"
                style={{backgroundImage: `url(${bgImg})`, backgroundRepeat: "no-repeat", backgroundSize: "cover"}}
            >
                <div className={"max-w-max mx-auto"}>
                    <main className="sm:flex">
                        <p className="text-4xl font-extrabold text-blue-600 sm:text-5xl">404</p>
                        <div className="sm:ml-6">
                            <div className="sm:border-l sm:border-gray-200 sm:pl-6">
                                <h1 className="text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                                    Page not found!
                                </h1>
                                <p className="mt-1 text-base text-gray-900">
                                    Please check the URL in the address bar and try again.
                                </p>
                            </div>
                            <div className="mt-6 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
                                <Link
                                    to="/"
                                    className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500s"
                                >
                                    Go back home
                                </Link>
                            </div>
                        </div>
                    </main>
                </div>
            </div>

            <Footer />
        </div>
    );
};

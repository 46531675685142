import {FormEvent, FunctionComponent, useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import {Helmet} from "react-helmet";
import ReactGA from "react-ga";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import {Header} from "../components/Header";
import {Footer} from "../components/Footer";
import {ErrorAlert} from "../components/ErrorAlert";
import api from "../api";

export const PasswordReset: FunctionComponent = () => {
    const [searchParams] = useSearchParams();
    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [captcha, setCaptcha] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [apiError, setApiError] = useState("");
    const [resetResponse, setResetResponse] = useState("");

    useEffect(() => {
        ReactGA.pageview(window.location.pathname);
    });

    function handleRequest(e: FormEvent) {
        e.preventDefault();
        setSubmitDisabled(true);

        if (!captcha) {
            setApiError("Please complete the captcha!");
            setSubmitDisabled(false);
            return;
        }

        api.post("https://api.cplegacy.com/password_request", {captcha, email})
            .then((res) => {
                if (res.status === 200) {
                    setResetResponse(res.data.message);
                } else {
                    setApiError(res.data.message);
                }
            })
            .catch((err) => {
                console.error(err);
                setResetResponse(err.response?.data.message ?? "An unknown error occurred. Please try again.");
            })
            .finally(() => {
                setSubmitDisabled(false);
            });
    }

    function handleNewPass(e: FormEvent) {
        e.preventDefault();
        setSubmitDisabled(true);

        if (!captcha) {
            setApiError("Please complete the captcha!");
            setSubmitDisabled(false);
            return;
        }

        if (password !== confirmPassword) {
            setApiError("Please make sure both passwords match!");
            setSubmitDisabled(false);
            return;
        }

        const token = searchParams.get("token") || "";
        api.post("https://api.cplegacy.com/password_reset", {captcha, token, password})
            .then((res) => {
                if (res.status === 200) {
                    setResetResponse(res.data.message);
                } else {
                    setApiError(res.data.message);
                }
            })
            .catch((err) => {
                console.error(err);
                setResetResponse(err.response?.data.message ?? "An unknown error occurred. Please try again.");
            })
            .finally(() => {
                setSubmitDisabled(false);
            });
    }

    return (
        <div className="min-h-screen bg-[#007BB8] flex flex-col">
            <Helmet>
                <title>Reset Your Password | Club Penguin Legacy</title>
            </Helmet>

            <Header />

            <div className={"flex-grow"}>
                {/* Banner Ad */}
                <div className={"max-w-7xl mx-auto pt-5 flex justify-center items-center"}>
                    <div id={"cplegacy-com_728x90"} />
                </div>

                <div className="text-center mx-auto px-4 py-8 sm:px-6 lg:px-8">
                    <h1 className="text-5xl font-bold leading-tight text-white">Reset your password</h1>
                </div>

                {apiError && (
                    <div className={"max-w-7xl mx-auto py-3"}>
                        <ErrorAlert
                            title={"Oops! There was a problem submitting the request"}
                            onClick={() => setApiError("")}
                        >
                            <ul role="list" className="list-disc pl-5 space-y-1">
                                <li>{apiError}</li>
                            </ul>
                        </ErrorAlert>
                    </div>
                )}

                <div className={"max-w-7xl mx-auto py-3"}>
                    <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
                        {resetResponse ? (
                            <div className={"text-lg font-bold text-center"}>{resetResponse}</div>
                        ) : (
                            <>
                                {searchParams.get("token") ? (
                                    <>
                                        {/* They've supplied a token, prompt for a new password */}
                                        <form className={"space-y-6"} onSubmit={handleNewPass}>
                                            <div>
                                                <label
                                                    htmlFor="password"
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    Password
                                                </label>
                                                <p className="my-2 text-sm text-gray-500">
                                                    Your new password must be at least 6 characters long, contain one
                                                    uppercase letter, one lowercase letter, and one number.
                                                </p>
                                                <div className="mt-1">
                                                    <input
                                                        id="password"
                                                        name="password"
                                                        type="password"
                                                        autoComplete="current-password"
                                                        minLength={6}
                                                        required
                                                        value={password}
                                                        onChange={(e) => setPassword(e.target.value)}
                                                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                    />
                                                </div>
                                            </div>

                                            <div>
                                                <label
                                                    htmlFor="confirm-password"
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    Confirm Password
                                                </label>
                                                <div className="mt-1">
                                                    <input
                                                        id="confirm-password"
                                                        name="confirm-password"
                                                        type="password"
                                                        autoComplete="current-password"
                                                        minLength={6}
                                                        required
                                                        value={confirmPassword}
                                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                    />
                                                </div>
                                            </div>

                                            <HCaptcha
                                                sitekey={"b602ca72-79f3-4cb5-a2f2-d1f1681efef6"}
                                                onVerify={setCaptcha}
                                            />

                                            <div>
                                                <button
                                                    type="submit"
                                                    disabled={submitDisabled}
                                                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                >
                                                    {submitDisabled && (
                                                        <svg
                                                            className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            fill="none"
                                                            viewBox="0 0 24 24"
                                                        >
                                                            <circle
                                                                className="opacity-25"
                                                                cx="12"
                                                                cy="12"
                                                                r="10"
                                                                stroke="currentColor"
                                                                strokeWidth="4"
                                                            ></circle>
                                                            <path
                                                                className="opacity-75"
                                                                fill="currentColor"
                                                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                            ></path>
                                                        </svg>
                                                    )}
                                                    Submit
                                                </button>
                                            </div>
                                        </form>
                                    </>
                                ) : (
                                    <>
                                        {/* Prompt for an email and captcha response */}
                                        <form className={"space-y-6"} onSubmit={handleRequest}>
                                            <div>
                                                <label
                                                    htmlFor="email"
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    Email
                                                </label>
                                                <div className="mt-1">
                                                    <input
                                                        id="email"
                                                        name="email"
                                                        type="email"
                                                        autoComplete="email"
                                                        required
                                                        value={email}
                                                        onChange={(e) => setEmail(e.target.value)}
                                                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                    />
                                                </div>
                                            </div>

                                            <HCaptcha
                                                sitekey={"b602ca72-79f3-4cb5-a2f2-d1f1681efef6"}
                                                onVerify={setCaptcha}
                                            />

                                            <div>
                                                <button
                                                    type="submit"
                                                    disabled={submitDisabled}
                                                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                >
                                                    {submitDisabled && (
                                                        <svg
                                                            className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            fill="none"
                                                            viewBox="0 0 24 24"
                                                        >
                                                            <circle
                                                                className="opacity-25"
                                                                cx="12"
                                                                cy="12"
                                                                r="10"
                                                                stroke="currentColor"
                                                                strokeWidth="4"
                                                            ></circle>
                                                            <path
                                                                className="opacity-75"
                                                                fill="currentColor"
                                                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                            ></path>
                                                        </svg>
                                                    )}
                                                    Submit
                                                </button>
                                            </div>
                                        </form>
                                    </>
                                )}
                            </>
                        )}
                    </div>
                </div>

                {/* Banner Ad */}
                <div className={"max-w-7xl mx-auto pt-5 flex justify-center items-center"}>
                    <div id={"cplegacy-com_728x90_2"} />
                </div>
            </div>

            <Footer />
        </div>
    );
};

import {FunctionComponent, useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import {Helmet} from "react-helmet";
import ReactGA from "react-ga";
import {Header} from "../components/Header";
import {Footer} from "../components/Footer";
import api from "../api";

export const Verify: FunctionComponent = () => {
    const [searchParams] = useSearchParams();
    const [verifyResponse, setVerifyResponse] = useState("");

    useEffect(() => {
        ReactGA.pageview(window.location.pathname);
    });

    function handleVerify(token: string) {
        api.post("https://api.cplegacy.com/verify_email", {token})
            .then((res) => {
                setVerifyResponse(res.data.message);
            })
            .catch((err) => {
                console.error(err);
                setVerifyResponse(err.response?.data.message ?? "An unknown error occurred. Please try again.");
            });
    }

    useEffect(() => {
        handleVerify(searchParams.get("token") || "");
    }, []);

    return (
        <div className="min-h-screen bg-[#007BB8] flex flex-col">
            <Helmet>
                <title>Verify Your Account | Club Penguin Legacy</title>
            </Helmet>

            <Header />

            <div className={"flex-grow"}>
                {/* Banner Ad */}
                <div className={"max-w-7xl mx-auto pt-5 flex justify-center items-center"}>
                    <div id={"cplegacy-com_728x90"} />
                </div>

                <div className="text-center mx-auto px-4 py-8 sm:px-6 lg:px-8">
                    <h1 className="text-5xl font-bold leading-tight text-white">Verify Your Account</h1>
                </div>

                <div className={"max-w-7xl mx-auto py-3"}>
                    <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
                        {verifyResponse ? (
                            <div className={"text-lg font-bold text-center"}>{verifyResponse}</div>
                        ) : (
                            <div className={"text-lg font-bold text-center"}>Verifying email, please wait...</div>
                        )}
                    </div>
                </div>

                {/* Banner Ad */}
                <div className={"max-w-7xl mx-auto pt-5 flex justify-center items-center"}>
                    <div id={"cplegacy-com_728x90_2"} />
                </div>
            </div>

            <Footer />
        </div>
    );
};
